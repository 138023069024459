<template>
    <div v-if="loadWheel" class="wheel-main">
        <div v-if="!disableWheel" @click="activateWheel">
            <Button inline button>Spin and WIN</Button>
        </div>
        <div v-else>
            <p>
                Έχεις κερδίσει <strong>{{ spinner.amount }}€</strong> από το κουπόνι <strong>{{ spinner.coupon }}</strong>.<br />Μπορείς να το εξαργυρώσεις στην Online αγορά του αυτοκινήτου.
            </p>
            <div class="wheel-main-buttons">
                <form v-if="spinner.coupon" class="wheel-main-email"  @submit.prevent="submitForm">
                    <input type="email" id="wheel-email-address" v-model="form.email"  autocomplete="email" required placeholder="Στείλε το στο email σου" />
                    <div>
                        <Button @click="submitForm" inline submit :processing="processing">Αποστολή</Button>
                    </div>
                </form>
                <Button anchor href="/vehicles" outline>Δες όλα τα αυτοκίνητα</Button>
            </div>
        </div>
        <SpinWheel :triggerWheel="triggerWheel" :numItems="numItems" @disable_wheel="disableButton"></SpinWheel>
    </div>
</template>

<script>
    import { useForm } from "@inertiajs/vue3";
    import Button from "../Button/Button.vue";
    import SpinWheel from './Wheel.vue';

    export default {
        components: {
            SpinWheel, Button
        },
        props: {
        },
        setup() {
            const form = useForm({
                email: ''
            });

            const errors = useForm({
                email: ''
            });

            return { form, errors }
        },
        data() {
            return {
                processing: false,
                loadWheel: false,
                numItems: [],
                triggerWheel: 0,
                disableWheel: false,
                spinner: {}
            }
        },
        methods: {
            axiosRequest() {
                axios.get(`${window.location.origin}/active-campaigns`).then((response) => {
                    this.numItems = response.data[0].wheel;
                    this.loadWheel = true;
                });
   
            },
            activateWheel() {
                this.triggerWheel += 1;
            },
            disableButton() {
                const getSpin = JSON.parse(window.localStorage.getItem('spin_autobesikos_1'));

                if ( getSpin ) {
                    this.disableWheel = true;
                    this.spinner = getSpin;
                }
            },
            submitForm() { 
                this.processing = true;
                const data = { ...this.form, coupon_code: this.spinner.coupon };
                axios.post('/coupon-notification', data).then((response) => {
                    // Clear values
                    this.form.reset();
                    this.errors.reset();
                    this.processing = false;
                    this.$helper.getFormMessage(true, this.$global.message, true, 'Ο κωδικός στάλθηκε στο email', 'green', response, this.$global.current_pathname);
                }).catch((error) => {
                    const getErrors = error.response.data.errors ? Object.entries(error.response.data.errors) : [];
                    this.errors.reset();
                    this.processing = false;
                    if (getErrors.length > 0) {
                        getErrors.map((e) => { this.errors[e[0]] = e[1][0]; })
                    } else {
                        this.$helper.getFormMessage(false, this.$global.message, true, 'Κάτι πήγε στραβά', 'red', error);
                    }
                })
            },
        },
        created() {
            this.axiosRequest();
            this.disableButton();
        }
    }
</script>