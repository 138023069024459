<template>
    <header id="header" :class="[menuExtend ? 'header-active' : '', $global.payment_method && 'header-payment']" class="no-print">
        <!-- Mobile -->
        <div v-if="!$global.payment_method" class="header-top no-select mobile-only">
            <div class="header-tel">
                <Phone color="#fff" />
                <a href="tel:+302108001111">210 800 1111</a>
            </div>
            <div class="header-user">
                <div @click="openPopup(true, 11)" class="header-spinner-text">
                    <Spinner />
                </div>
                <div @click="closeMobileMenu">
                    <Submenu :submenu="user">
                        <User color="#fff" />
                    </Submenu>
                </div>        
            </div>        
        </div>
        <div class="header" :class="[menuExtend ? 'header-active' : '', $global.payment_method && 'header-payment-main']">
            <div class="header-main no-select">
                <div class="header-left">
                    <Link class="header-logo" href="/">
                        <Logo :color="menuExtend ? '#fff' : ''" />
                    </Link>
                    <nav v-if="!$global.payment_method" class="header-menu desktop-only">
                        <div v-for="item in menu" :key="item.name">
                            <Link v-if="item.href" :href="item.href">{{ item.name }}</Link>
                            <Submenu v-else :text="item.name" :submenu="item.submenu"/>
                        </div>
                    </nav>
                    <div v-else class="header-payment-menu">
                        <p>{{ paymentName }}</p>
                    </div>
                </div>
                <div class="header-right">
                    <div v-if="!$global.payment_method" class="header-right-desktop desktop-only">
                        <section class="header-spinner">
                            <div @click="openPopup(true, 11)" class="header-spinner-text">
                                <Spinner />
                            </div>
                        </section>
                        <div class="header-tel">
                            <Phone />
                            <a href="tel:+302108001111">210 800 1111</a>
                        </div>
                        <!-- <div class="header-language">
                            <button type="button" class="header-language-active">EL</button> | <button type="button" class="">EN</button>
                        </div> -->
                        <div class="header-user">
                            <Submenu :submenu="user">
                                <User />
                            </Submenu>
                        </div>
                        <div class="header-info">
                            <Button outline href="/vehicles?sale_price[]=1">{{ $global.isTablet ? 'Π' : 'Δες π' }}ροσφορές</Button>
                        </div>
                    </div>
                    <div v-else class="header-payment-content">
                        <div v-for="item in payment" :key="item.id">
                            <p :class="paymentID >= item.id ? 'header-payment-content-active' : ''">{{ item.id }}</p>
                            <p :class="paymentID === item.id && 'header-payment-content-text'">{{ item.name }}</p>
                        </div>
                    </div>
                    <div v-if="!$global.payment_method" class="header-right-mobile mobile-only">
                        <div :class="menuExtend ? 'header-mobile-menu-active' : ''" class="header-mobile-menu" @click="mobileMenu">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Mobile -->
        <div :class="menuExtend ? 'header-bottom-active' : ''" class="header-bottom no-select mobile-only">
            <nav class="header-mobile-nav">
                <div v-for="item in menu" :key="item.name">
                    <Link v-if="item.href" :href="item.href" @click="mobileMenu">{{ item.name }}</Link>
                    <SubmenuMobile v-else :text="item.name" :submenu="item.submenu" @triggerMenu="mobileMenu" />
                </div>
                <div @click="closeMobileMenu">
                    <Button inline href="/vehicles?sale_price[]=1">Δες προσφορές</Button>
                </div>
            </nav>
        </div>
        <PopupSmall @popup_open="popupOpen" v-if="$global.popup.id === 11" :id="11">
            <WheelView />
        </PopupSmall>
    </header>
</template>

<script>
    import { Logo, User, Phone, Arrow, Spinner } from "../Icons/Icons";
    import Button from "../Components/Button/Button.vue";
    import Submenu from "../Components/Submenu/Submenu.vue";
    import SubmenuMobile from "../Components/Submenu/SubmenuMobile.vue";
    import WheelView from "../Components/Wheel/WheelView.vue";
    import PopupSmall from '../Components/Popup/PopupSmall.vue';

    export default {
        components: {
            Logo, Button, User, Phone, Arrow, Submenu, SubmenuMobile, Spinner, PopupSmall,
            WheelView
        },
        setup(props, { slots, attrs }) {
            const menu = [
                { name: 'Αυτοκίνητα', 
                    submenu: [
                       { name: 'Όλα τα Αυτοκίνητα', href: '/vehicles' },
                       { name: 'Θέλω Καινούργιο', href: '/vehicles?condition[]=Καινούργιο' },
                       { name: 'Θέλω Μεταχειρισμένο', href: '/vehicles?condition[]=Μεταχειρισμένο' },
                    ]
                },
                { name: 'Ποιοι είμαστε', href: '/about-us' },
                { name: 'Υπηρεσίες',
                    submenu: [
                        { name: 'Ασφάλεια', href: '/insurance' },
                        { name: 'Χρηματοδότηση', href: '/auto-loan-calculator' },
                        { name: 'Χρηματοδότηση από την Santander', href: '/online-xrhmatodotish-apo-thn-santander' },
                        { name: '5πλή Εγγύηση', href: '/warranty-used-car' },
                        { name: 'Service', href: '/service' },
                        { name: 'Leasing', href: 'https://leasingplanet.gr/', external: true },
                    ]
                },
                { name: 'Blog', href: '/blog' },
                { name: 'Επικοινωνία', href: '/contact-us' },
            ];
            
            const payment = [
                { id: 1, name: 'Σύνδεση / Δημιουργία λογαριασμού', url: '/login' },
                { id: 2, name: 'Στοιχεία πελάτη & Πληρωμή', url: '/checkout'  },
                { id: 3, name: 'Ολοκλήρωση κράτησης', url: '/success'  },
            ];

            return { menu, payment }
        },
        data() {
            return {
                menuExtend: false,
                paymentURL: '',
                paymentID: 0,
                paymentName: '',
            }
        },
        inject: ['watchPaymentMethod'],
        watch: {
            watchPaymentMethod: { handler() { this.paymentMenu() }, deep: true },
        },
        computed: {
            user() {
                if ( !this.$attrs.user ) {
                    return [
                        { name: 'Σύνδεση', href: '/login' },
                        { name: 'Εγγραφή', href: '/register' }
                    ]
                } else {
                    return [
                        { name: 'Λογαριασμός', href: '/account' },
                        { name: 'Αποσύνδεση', href: '/logout' }
                    ]
                }
            },
        },
        methods: {
            openPopup(open, id) {
                this.$global.popup.open = open;
                this.$global.popup.id = id;
            },
            closeMobileMenu() {
                this.menuExtend = false;
                document.body.classList.remove("hide-scroll");
            },
            mobileMenu() {
                this.menuExtend = !this.menuExtend;
                document.body.classList.toggle("hide-scroll");
            },
            paymentMenu() {
                if ( this.$global.payment_method ) {
                    if ( !this.$attrs.user ) {
                        this.paymentName = this.payment[0].name;
                        this.paymentID = this.payment[0].id;
                    } else {
                        this.payment.forEach((item) => {
                            if ( this.$global.current_url.includes(item.url) ) { 
                                this.paymentName = item.name;
                                this.paymentID = item.id;
                            }
                        })
                    }
                } else { this.paymentName = ''; }
            },
            popupOpen(value) {},
        },
        mounted() {
            this.paymentMenu();
            if ( !window.localStorage.getItem('spin_autobesikos_1') && !window.localStorage.getItem('spin_autobesikos_stop_1') ) {
                this.openPopup(true, 11);
            }
        },
    }
</script>