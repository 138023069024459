<template>
    <div class="wheel-wrapper" ref="wheelContainer" @click="spinWheel"></div>
</template>
  
<script>
    import { Wheel } from "spin-wheel";
    import { ref, onMounted, nextTick, reactive } from "vue";

    export default {
        components: {
            Wheel
        },
        props: {
            numItems: Array,
            triggerWheel: Number
        },
        setup(props, { slots, attrs }) {
            const wheelContainer = ref(null);
            let wheel = ref(null);

            let img = document.createElement('img');
            img.src = '/images/example-2-overlay.svg';

            let active = reactive({
                message: 0
            });

            let spinning = reactive({
                state: false
            });

            const wheelProps = {
                name: 'Movies',
                radius: 0.88,
                itemLabelRadius: 0.92,
                itemLabelRadiusMax: 0.4,
                itemLabelRotation: 0,
                itemLabelBaselineOffset: -0.13,
                itemBackgroundColors: ['#FF3B00', '#fff'],
                itemLabelColors: ['#fff', '#FF3B00'],
                rotationSpeedMax: 700,
                rotationResistance: -70,
                lineWidth: 1,
                lineColor: '#000',
                items: [],
                overlayImage: img,
                onRest: (e) => {
                    active.message += 1;
                    spinning.state = false;
                }
            };

            // Use `onMounted` for lifecycle hook
            onMounted(async () => {
                // Wait until DOM updates are completed
                await nextTick();

                if (wheelContainer.value) {
                    // Ensure that the wheelContainer is an actual DOM element
                    wheel.value = new Wheel(wheelContainer.value, wheelProps);
                }
            });

            for (let i = 0; i < props.numItems.length; i++) {
                wheelProps.items.push({
                    label: props.numItems[i].indexOf('δοκ') === -1 ? `${props.numItems[i]}€` : `${props.numItems[i]}`,
                });
            }
            
            return { wheelContainer, wheelProps, wheel, active, spinning }
        },
        data() {
            return {
                message: ''
            }
        },
        emits: ['disable_wheel'],
        watch: {
            active: { handler() { 
                if ( JSON.parse(window.localStorage.getItem('spin_autobesikos_1')) && !JSON.parse(window.localStorage.getItem('spin_autobesikos_1')).once ) {
                    this.spinMessage(this.message);
                    window.localStorage.setItem('spin_autobesikos_1', JSON.stringify({ ...JSON.parse(window.localStorage.getItem('spin_autobesikos_1')), once: true }));
                    this.$emit('disable_wheel');
                } else {
                    if ( !JSON.parse(window.localStorage.getItem('spin_autobesikos_1')) ) {
                        this.spinMessage('Ξαναδοκίμασε');
                    } else {
                        this.spinMessage('Δεν μπορείς να ξανά παίξεις άλλη φορά');
                    }
                }
            }, deep: true },
            triggerWheel: { handler() { this.spinWheel(); }, deep: true },
        },
        methods: {
            async spinWheel() {
                if ( !this.spinning.state && !window.localStorage.getItem('spin_autobesikos_1') ) {
                    this.spinning.state = true;
                    axios.post(`${window.location.origin}/wheel-result/1`).then(async (response) => {
                        if ( !response.data.retry ) {
                            this.message = `Συγχαρητήρια! Κέρδισες ${response.data.winner}€ έκπτωση! Εξαργύρωσε το κουπόνι ${response.data.coupon_code} στην Online αγορά`;
                        } else {
                            this.message = `Ξαναδοκίμασε!`
                        }
                        this.wheel.spinToItem(response.data.winner_index, 8000, true, 7, 1, null);
                        if ( !response.data.retry ) {
                            window.localStorage.setItem('spin_autobesikos_1', JSON.stringify({
                                coupon: response.data.coupon_code,
                                amount: response.data.winner,
                                once: false,
                            }));
                        }
                    });
                }
            },
            spinMessage(value) {
                this.$helper.getFormMessage(true, this.$global.message, true, value, 'orange', null, null);
            }
        },
        mounted() {
        }
    }
</script>