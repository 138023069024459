<template>
    <Head>
        <title>Μεταχειρισμένα αυτοκίνητα AutoBesikos -  Βρείτε το επόμενο σας αυτοκίνητο</title>
        <meta name="description" content="Μεταχειρισμένα αυτοκίνητα Autobesikos. Στα καταστήματά μας θα βρεις το επόμενο μεταχεισμένο αυτοκίνητό σου. Μεταχειρισμένα αυτοκίνητα πόλης, Sedan, SUV & 4X4">
    </Head>

    <section class="home-hero">
        <img src="/images/home-hero-image-v1.webp" alt="AutoBesikos - Main" width="1920" height="800">
        <div class="home-hero-search mobile-only">
            <Search mobile />
        </div>
        <div class="home-hero-text">
            <h1>Οδήγησε τα όνειρά σου με Αυτοπεποίθηση</h1>
            <h2>Η αξιοπιστία και η εγγύηση της AutoBesikos θα σε ακολουθούν σε όλες τις διαδρομές σου.</h2>
            <div class="mobile-only">
                <Button inline href="/vehicles">Όλα τα αυτοκίνητα</Button>
            </div>
        </div>
        <div class="home-search desktop-only">
            <div class="reverse-curved-corner reverse-curved-corner-right"></div>
            <Search @search_item="searchItem" />
            <Button inline anchor :href="searchValue">Αναζήτηση</Button>
            <div class="reverse-curved-corner reverse-curved-corner-left"></div>
        </div>
    </section>
    <section class="home-video">
        <div class="home-heading">
            <h2>Πλήρως <span>Ελεγμένα</span> Μεταχειρισμένα.<br/>Μπες, Δες, Βρες!</h2>
        </div>
        <div @click="openVideo(true, 10)" class="home-video-image">
            <Play />
            <img src="/images/home-video-v1.png" :alt="`AutoBesikos - Μπες, Δες, Βρες!`" width="1067" height="600">
        </div>
        <Popup @popup_open="popupOpen" v-if="$global.popup.id === 10" :id="10">
            <iframe title="Youtube Video" width="100%" height="100%" :src="('https://www.youtube-nocookie.com/embed/QMbN4FzdyB0?rel=0')"></iframe>
        </Popup>
    </section>
    <section class="home-slider">
        <div class="home-heading">
            <h2>Δες αυτοκίνητα με βάση<br/> το <span>Budget</span> σου</h2>
        </div>
        <div class="home-slider-main form-main">
            <form @submit.prevent="submitForm">
                <div class="home-slider-1">
                    <div class="desktop-only">
                        <Button @click="submitForm" outline submit >Αναζήτηση</Button>
                    </div>
                    <div class="mobile-only" >
                        <Button @click="submitForm" inline submit >Αναζήτηση</Button>
                    </div>
                </div>
                <CarPayment @dosi_value="getDoseis" @prokatavoli_value="getProkatavoli" className="home-slider" />
            </form>
        </div>
    </section>
    <section class="home-new-cars">
        <div class="home-heading">
            <h2><span>Νέες</span> αφίξεις</h2>
            <p>Ανακαλύψτε τις νέες αφίξεις αυτοκινήτων στην AutoBesikos για απόλυτη οδηγική εμπειρία.</p>
        </div>
        <SliderNewCars :cars="$attrs.latestCars.data" :user="$attrs.auth.user" @favorite_car="updateFavorites" />
    </section>
    <section class="home-services">
        <div class="home-heading">
            <h2>Προσφερόμενες υπηρεσίες</h2>
            <p>Η AutoBesikos προσφέρει ολοκληρωμένες υπηρεσίες στον κλάδο των αυτοκινήτων. Από χρηματοδότηση και εξυπηρέτηση Service, μέχρι ασφάλεια αυτοκινήτου και ευέλικτο Leasing</p>
        </div>
        <div class="home-services-main">
            <div class="home-services-grid">
                <div :class="`home-services-item home-services-item-${index+1}`" v-for="(service, index) in services" :key="index">
                    <img :src="service.img" :alt="`AutoBesikos - ${service.img_alt}`" :width="index === 0 || index === 3 ? '831' : '587'" height="362">
                    <div>
                        <h3>{{ service.title }}</h3>
                        <p>{{ service.text }}</p>
                        <Button v-if="index !== 3" inline :href="service.href">Δες περισσότερα</Button>
                        <Button v-else anchor inline :href="service.href" target="_blank">Δες περισσότερα</Button>
                    </div>
                </div>
            </div>
            <div class="home-services-full">
                <div class="home-services-item">
                    <img src="/images/warranty-used-car.webp" alt="AutoBesikos - 5πλή Εγγύηση Μεταχειρισμένου" width="1920" height="383">
                    <div class="home-services-item-warranty">
                        <h3>5πλή Εγγύηση Μεταχειρισμένου</h3>
                        <p>Για να σε απαλλάξουμε από το άγχος και την αβεβαιότητα που μπορεί να σου προκαλεί η αγορά ενός Μεταχειρισμένου οχήματος, σου προσφέρουμε ένα μοναδικό πακέτο 5πλής Εγγύησης!</p>
                        <Button inline href="/warranty-used-car">Δες περισσότερα</Button>
                    </div>          
                </div>
            </div>
        </div>
    </section>
    <section class="home-reviews">
        <div class="home-heading">
            <h2>Reviews</h2>
            <p>Η εμπιστοσύνη των πελατών είναι ο βασικός πυλώνας της AutoBesikos. Με αξιοπιστία και προσήλωση στην ποιότητα, κερδίζουμε την εμπιστοσύνη τους.</p>
        </div>
        <SliderReviews />
    </section>
    <section class="home-blog">
        <div class="home-heading">
            <h2>Τελευταία άρθρα</h2>
            <p>Διαβάστε τα άρθρα για το πάθος μας που δεν είναι άλλο από το αυτοκίνητο!</p>
        </div>
        <div v-if="$attrs.latestBlogs" class="blog-grid">
            <Link class="blog-item" v-for="(item, index) in $attrs.latestBlogs.data" :key="index" :href="`/blog/${item.slug}`">
                <img :src="item.image.original_url" :alt="`AutoBesikos - ${item.img_alt}`" width="468" height="312">
                <div>
                    <h3>{{ item.title }}</h3>
                    <p>{{ item.meta_description }}</p>
                    <p>{{ $helper.convertTimeToMonth(item.created_at) }}</p>
                </div>
            </Link>
        </div>
        <div class="blog-button">
            <Button outline href="/blog">Δες όλα τα άρθρα</Button>
        </div>
    </section>

    <!-- Favorites -->
    <RefreshPageFavorites :user="$attrs.auth.user" :favorites_cars="favorites_cars" :favorites_cars_trigger="favorites_cars_trigger" @postChange="triggerFavorite" />
    <!-- End Favorites -->
</template>

<script>
    import Button from "../Components/Button/Button.vue";
    import SliderNewCars from "../Components/Slider/SliderNewCars.vue";
    import SliderReviews from "../Components/Slider/SliderReviews.vue";
    import { router } from "@inertiajs/vue3";
    import { reactive, computed } from "vue";
    import Search from "../Components/Search/Search.vue";
    import CarPayment from "../Components/Car/CarPayment.vue";
    import RefreshPageFavorites from "../Components/LocalStorage/RefreshPageFavorites.vue";
    import Popup from '../Components/Popup/Popup.vue';
    import { Play } from '../Icons/Icons';
    import PopupSmall from '../Components/Popup/PopupSmall.vue';

    export default {
        components: {
            Play,
            Button, SliderNewCars, SliderReviews, Search, CarPayment, RefreshPageFavorites, Popup, PopupSmall,
        },
        props: {
        },
        setup() {
            const services = [
                { title: 'Χρηματοδότηση από την AutoBesikos', text: 'Τώρα στην AutoBesikos, επίλεξε το αυτοκίνητο που θέλεις και επίλεξε live χρηματοδότηση από τον υπολογστή ή το κινητό σου', img: '/images/services-home-image-1-v1.webp', img_alt: 'Χρηματοδότηση από την AutoBesikos', href: '/auto-loan-calculator' },
                { title: 'Ασφάλειες και οδική βοήθεια', text: 'Μπορείτε να ασφαλίσετε το αυτοκίνητό σας χρησιμοποιώντας ολοκληρωμένα πακέτα ασφάλισης', img: '/images/services-home-image-2-v2.webp', img_alt: 'Ασφάλειες και οδική βοήθεια', href: '/insurance' },
                { title: 'Service Center', text: 'Στην AutoBesikos όλα τα αυτοκίνητα ελέγχονται και αποκτούν την εγγύηση της εταιρείας μας.', img: '/images/services-home-image-3-v1.webp', img_alt: 'Service Center', href: '/service' },
                { title: 'Leasing Planet', text: 'Το Leasing διαφορετικό από αυτό που ήξερες!', img: '/images/services-home-image-4-v1.webp', img_alt: 'Leasing Plane', href: 'https://leasingplanet.gr' },
            ]

            const chrimatodotisi = reactive({
                doseis: 48,
                prokatavoli: 3000,
            })

            return { services, chrimatodotisi }
        },
        data() {
            return {
                update_favorites: 0,
                favorites_cars: [],
                favorites_cars_trigger: false,
                searchValue: '/vehicles',
                open: false,
            }
        },
        provide() {
            return {
                // reactive global provider
                updateFavorites: this.update_favorites,
            }
        },
        methods: {
            popupOpen(value) {},
            openPopup(open, id) {
                this.$global.popup.open = open;
                this.$global.popup.id = id;
            },
            openVideo(open, id) {
                this.$global.popup.open = open;
                this.$global.popup.id = id;
            },
            searchItem(value) {
                this.searchValue = `/vehicles?s=${value}`;
            },
            getDoseis(value) {
                this.chrimatodotisi.doseis = value;
            },
            getProkatavoli(value) {
                this.chrimatodotisi.prokatavoli = value;
            },
            submitForm() { 
                router.get('/vehicles', { 
                    doseis: this.chrimatodotisi.doseis, 
                    prokatavoli: this.chrimatodotisi.prokatavoli
                })
            },
            triggerFavorite(value) {
                // Observe the window changes and re-calculate the active favorites
                this.favorites_cars_trigger = value;
                this.favorites_cars.length = 0;
            },
            updateFavorites(id) {
                // Update the array
                if ( !this.favorites_cars.includes(id) ) {
                    this.favorites_cars.push(id);
                } else {
                    const index = this.favorites_cars.indexOf(id);
                    if ( index > -1 ) {
                        this.favorites_cars.splice(index, 1);
                    }
                }

                // Update between tabs - browser
                if ( this.favorites_cars.length > 0 ) {
                    this.favorites_cars_trigger = true;
                } else {
                    this.favorites_cars_trigger = false;
                }
            },
        },
        mounted() {
        },
        beforeUnmount() {
            if ( this.favorites_cars.length > 0  ) {
                axios.post(`${this.$global.origin}/account/favorites`, { ids: this.favorites_cars }).then((data) => { return data });
            }
        },
    }
</script>