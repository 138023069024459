<template>
    <div class="car-block-top">
        <div class="car-block-bar no-select" :class="car.photo_exterior.length === 0 && 'car-block-bar-ext'">
            <div class="car-block-bar-top">
                <p v-if="car.sold > 0" class="car-block-bar-sold">Πουλημένο</p>
                <p v-else-if="car.reserved > 0" class="car-block-bar-reserved">Δεσμευμένο</p>
                <!-- <p v-if="car.offer === 1" class="car-block-bar-offer">Black Friday Offer</p> -->
            </div>
            <div class="car-block-bar-bottom">
                <div @click="addToFavorite" class="car-block-bar-heart">
                    <Heart :favorite="favorite" :color="favorite ? '#FF3B00' : '#FFF'"  />
                </div>
            </div>
        </div>

        <a :href="`/vehicles/${car.id}`" :title="`Car - ${car.make} ${car.model}`" class="car-block-image">
            <swiper v-if="car.photo_exterior.length > 0" class="car-block-image-carousel" :lazy="true" :slides-per-view="1" :space-between="1" :modules="modules" @slideChange="onSlideChange" :navigation="true" :pagination="{type: 'fraction' }">
                <swiper-slide v-for="(image, index) in car.photo_exterior" :key="index">
                    <img :src="image" :alt="car.model+' - View model'" width="376" height="250" loading="lazy">
                </swiper-slide>
            </swiper>
            <img v-else :src="$global.no_image" :alt="car.model+' - View model'" width="376" height="250">
        </a>
    </div>
    <a :href="`/vehicles/${car.id}`" :title="`Car - ${car.make} ${car.model}`" class="no-select">
        <div v-if="!noDetails" class="car-block-grid">
            <p v-if="car.regiyear">{{ car.regiyear }}</p>
            <p v-if="car.mileage">{{ car.mileage.toLocaleString('el-GR') }} χλμ.</p>
            <p v-else>{{ car.condition }}</p>
            <p v-if="car.drive">{{ car.drive }}</p>
        </div>
        <div :class="!noDetails ?'car-block-head' : 'car-block-no-details'">
            <h2>{{ car.make ? car.make : ''}} {{ car.model ? car.model : '' }} {{ car.version ? car.version : '' }}</h2>
            <CarPrice :price="car.price" :salePrice="car.sale_price" :without_vat="car.without_vat"/>
        </div>
        <div v-if="!noDetails || car.installments || car.reserved > 0 || car.sold > 0" class="car-block-bottom" :class="car.reserved > 0 || car.sold > 0 ? 'car-block-bottom-has-text' : ''">
            <!-- Monthly price -->
            <p v-if="car.installments" class="car-block-bottom-installments">ή με {{ car.installments.toLocaleString('el-GR') }}€/μήνα</p>
        </div>
    </a>
</template>

<script>
    import CarPrice from "./CarPrice.vue"; 
    import { Swiper, SwiperSlide } from "swiper/vue";
    import { Pagination, Navigation } from "swiper";
    import { Heart } from "../../Icons/Icons";
    import "swiper/css/navigation";
    import "swiper/css";
    import "swiper/css/pagination";
    import { router } from "@inertiajs/vue3";

    export default {
        components: {
            CarPrice,
            Swiper, SwiperSlide,
            Heart,
        },
        props: {
            car: Object,
            user: Object,
            noDetails: Boolean,
        },
        setup(props, { slots, attrs }) {
            return { modules: [Pagination, Navigation] };
        },
        data() {
            return {
                favorite: window.localStorage.getItem('favorites_cars') ? window.localStorage.getItem('favorites_cars').includes(this.car.id) : false,
            }
        },
        emits: ['favorite_car'],
        // import global provider
        inject: ['updateFavorites'],
        watch: {
            updateFavorites: function() { 
                if ( this.$global.favorites_array.includes(this.car.id) ) {
                    if ( !this.favorite ) {
                        this.favorite = true;
                    }
                } else { 
                    if ( this.favorite ) {
                        this.favorite = false;
                    }
                }
            }
        },
        methods: {
            onSlideChange(swiper){
            },
            addToFavorite() {
                if ( this.user ) {
                    this.favorite = !this.favorite;
                    this.emitFavorite(this.car.id);

                    // Get the current local storage - id
                    const arrayFavorites = JSON.parse(window.localStorage.getItem('favorites_cars')).map((item) => item.id);
                    const indexFavorite = arrayFavorites.indexOf(this.car.id);

                    // If the item exists in the array
                    if ( indexFavorite > -1 ) {
                        // Remove the item
                        const removeFavorite = JSON.parse(window.localStorage.getItem('favorites_cars')).filter((item) => item.id !== this.car.id);
                        window.localStorage.setItem('favorites_cars', JSON.stringify(removeFavorite))
                    } else {
                        // Push the item
                        let arrayStorage = JSON.parse(window.localStorage.getItem('favorites_cars'));
                        arrayStorage.push({ id: this.car.id });

                        window.localStorage.setItem('favorites_cars', JSON.stringify(arrayStorage));
                    }
                } else {
                    router.visit('/login', {})
                }
            },
            emitFavorite(id) {
                this.$emit('favorite_car', id);
            },
        },
    }
</script> 